@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-var-ui/dist/index.css';


html, body, #root {
  width: 100%;
  height: 100%;
}

.plotlyjsicon {
  visibility: hidden;
}

.green-button-container button {
  background-color: rgb(33, 153, 33);
}

.green-button-container button:hover {
  background-color: rgb(19, 123, 19);
}